import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_About = _resolveComponent("About")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "welcomeRef",
    class: _normalizeClass({'welcomeMax':!_ctx.hasEditPortal,'welcomeMin':_ctx.hasEditPortal})
  }, [
    (!_ctx.hasEditPortal)
      ? (_openBlock(), _createBlock(_component_About, { key: 0 }))
      : _createCommentVNode("", true)
  ], 2))
}